import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ProficloudService } from '@services/proficloud.service'
import { UiService } from '@services/ui/ui.service'
import { OcppProxyService } from '../../services/ocpp-proxy.service'

@Component({
  selector: 'app-ocpp-proxy-add-backend-provider-modal',
  templateUrl: './ocpp-proxy-add-backend-provider-modal.component.html',
  styleUrls: ['./ocpp-proxy-add-backend-provider-modal.component.scss'],
})
export class OcppProxyAddBackendProviderModalComponent implements OnInit {
  addBackendProviderForm: UntypedFormGroup

  providerNameControl = new UntypedFormControl('', [Validators.maxLength(80), Validators.required])

  backendURLControl = new UntypedFormControl('', [Validators.maxLength(80), Validators.required])

  constructor(
    public ui: UiService,
    public proficloud: ProficloudService,
    public ocppProxy: OcppProxyService
  ) {}

  ngOnInit(): void {
    this.addBackendProviderForm = new UntypedFormGroup({
      provider: this.providerNameControl,
      url: this.backendURLControl,
    })
  }

  clearForm() {
    this.providerNameControl.setValue('')
    this.backendURLControl.setValue('')
  }

  submit() {
    this.ocppProxy.addBackendProvider(this.providerNameControl.value, this.backendURLControl.value)

    this.clearForm()
    this.ocppProxy.showAddBackendProviderOverlay$.next(false)
  }
}
